/* eslint-disable no-console */
import React, { useState } from 'react';
import { FloatButton, Modal, Form, Input, Select, message, Popover } from 'antd';
import { CommentOutlined, CustomerServiceOutlined, QuestionOutlined } from '@ant-design/icons';
import { createFeedback } from '@/api/feedback';
import { onClickDoc } from '@utils/util';
import store from '@/store';

import './index.less';

const Option = Select.Option;
const { TextArea } = Input;

const feebackCategory = [
    {
        label: '问题反馈',
        value: 'DEBUG'
    },
    {
        label: '优化建议',
        value: 'SUGESTIONS'
    },
    {
        label: '其他问题',
        value: 'OTHER'
    }
];

function CustomerService() {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const state = store.getGlobalState();

    function cancelModal() {
        setVisible(false);
        form.resetFields();
    }

    function submit() {
        form.validateFields().then(async (values) => {
            setLoading(true);

            const resp = await createFeedback(values);

            setLoading(false);

            if (resp) {
                cancelModal();
                messageApi.open({
                    type: 'success',
                    content: '您反馈的问题我们已经收到，请耐心等待后续通知！'
                });
            }
        });
    }

    function onClickManual() {
        // 展示客户微信二维码
    }

    function onClickWorkOrder() {
        // 跳转到问题反馈界面
        setVisible(true);
    }

    function onOpenChange(value) {
        setOpen(value);
    }

    return (
        <div>
            <FloatButton.Group
                trigger="hover"
                type="primary"
                description="联系客服"
                className={`global-customer-service ${open ? 'open' : ''}`}
                icon={!state.isMobile && <CustomerServiceOutlined />}
                open={open}
                onOpenChange={onOpenChange}
            >
                <FloatButton icon={<QuestionOutlined />} onClick={onClickDoc} />
                <FloatButton onClick={onClickWorkOrder} />
                <Popover
                    placement="left"
                    content={
                        <div className="quota-content">
                            <div className="image">
                                <img src="/images/customerServiceQr.png"></img>
                            </div>
                            <div className="description">扫码联系客服</div>
                        </div>
                    }
                    trigger="click"
                >
                    <FloatButton icon={<CommentOutlined />} onClick={onClickManual} />
                </Popover>
            </FloatButton.Group>

            <Modal
                title="反馈问题"
                width={660}
                open={visible}
                confirmLoading={loading}
                onOk={submit}
                onCancel={cancelModal}
            >
                <Form form={form} initialValues={{}}>
                    <Form.Item name="category" label="问题类别" rules={[{ required: true }]}>
                        <Select placeholder="请选择类别">
                            {feebackCategory.map((item) => {
                                const { label, value } = item;
                                return (
                                    <Option key={value} value={value}>
                                        {label}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item name="title" label="问题标题" rules={[{ required: true }]}>
                        <Input autoComplete="off" />
                    </Form.Item>
                    <Form.Item name="description" label="问题描述" rules={[{ required: true }]}>
                        <TextArea rows={4} autoComplete="off" />
                    </Form.Item>
                </Form>
            </Modal>

            {contextHolder}
        </div>
    );
}

export default CustomerService;
