/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect, useContext } from 'react';
import { Divider, Dropdown, Tooltip, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Menus from '@components/Menus';
import { LANG } from '@utils/constants';
import store from '@/store';
import { logout } from '@/api/common';
import i18n, { LANG_KAY } from '@utils/i18n';
import { copyToClipboard, gotoLogin, onClickDoc } from '@utils/util';
import commonContext from '@utils/commonContext';
import { headerConfig } from './config';

import { ReactComponent as ShareSvg } from '@/assets/images/share.svg';
import { ReactComponent as LogoSvg } from '@/assets/images/logoNew.svg';

import './index.less';

export default (props) => {
    const consumer = useContext(commonContext);
    const { defaultLang, changeLang } = props;
    const [selectedKeys, setSelectedKeys] = useState([defaultLang]);
    const [userInfoMenu, setUserInfoMenu] = useState(headerConfig());
    const ref = useRef({ langItem: LANG[defaultLang.replace('-', '')] });
    const state = store.getGlobalState();

    function onSelectLang(e) {
        const { key } = e;

        ref.current.langItem = LANG[key.replace('-', '')];
        setSelectedKeys([key]);
        changeLang(key);
        store.setGlobalState({ lang: key });
    }

    function onClickShare() {
        try {
            copyToClipboard(window.location.href);
            message.success(i18n.get(LANG_KAY.TIPS_HEADER_SHARE_COPY_SUCCESS));
        } catch (e) {
            //
        }
    }

    function onClickLogout() {
        logout();
        gotoLogin();
    }

    useEffect(() => {
        if (consumer && consumer.length > 0) {
            const consumerKeys = consumer.map((item) => item.name);

            const arr = userInfoMenu.filter((item) => {
                return consumerKeys.includes(item.key);
            });

            setUserInfoMenu(arr);
        }
    }, [consumer]);

    const menuCfg = [
        ...userInfoMenu,
        {
            key: 'logout',
            label: <div onClick={onClickLogout}>{i18n.get(LANG_KAY.TITLE_HEADER_LOG_OUT)}</div>
        }
    ];

    return (
        <header className="page-header">
            <Helmet>
                <link
                    rel="icon"
                    type="image/svg+xml"
                    href={
                        state.isHongCustomer
                            ? '/images/logoNew.svg'
                            : state.user?.sourceType === 8 || state.isWhaleCustomer
                            ? '/images/tikTalentsLogoBlack.png'
                            : '/images/logoNew.svg'
                    }
                />
            </Helmet>

            <div className="left">
                {!state.isHongCustomer && !state.isWhaleCustomer && <Menus lang={defaultLang} />}

                {state.isHongCustomer ? (
                    <LogoSvg className="logo" />
                ) : state.user?.sourceType === 8 || state.isWhaleCustomer ? (
                    <img className="logo" style={{ height: 48, width: 48 }} src="/images/tikTalentsLogo.png"></img>
                ) : state.user?.sourceType ? (
                    <LogoSvg className="logo" />
                ) : (
                    ''
                )}

                <Link className="system-name" to="/">
                    {state.isHongCustomer
                        ? '生意通'
                        : state.user?.sourceType === 8 || state.isWhaleCustomer
                        ? '鲸客'
                        : state.user?.sourceType
                        ? '拓云智能'
                        : ''}
                </Link>

                {(state.isHongCustomer || state.isWhaleCustomer) && (
                    <div className="horizontal-menu-content">
                        <Link className="menu-item" to="/">
                            首页
                        </Link>
                    </div>
                )}
            </div>

            <div className="right">
                {!state.isMobile && (
                    <>
                        <div className="item" onClick={onClickDoc}>
                            {i18n.get(LANG_KAY.TITLE_HEADER_DOCUMENT_CENTER)}
                        </div>
                        <Divider type="vertical" className="item-line" />

                        <div className="item">{i18n.get(LANG_KAY.TITLE_HEADER_INRELIIGENT_CUSTOMER_SERVICE)}</div>
                        <Divider type="vertical" className="item-line" />
                    </>
                )}

                <Tooltip title={i18n.get(LANG_KAY.TITLE_HEADER_SHARE)} color="rgba(0,0,0, 0.6)">
                    <ShareSvg className="item" onClick={onClickShare} />
                </Tooltip>
                <Divider type="vertical" className="item-line" />

                {/* <Dropdown
                    menu={{
                        selectedKeys,
                        items: Object.values(LANG),
                        onClick: onSelectLang
                    }}
                    placement="bottom"
                >
                    <div className="item">{ref.current.langItem.label}</div>
                </Dropdown>
                <Divider type="vertical" className="item-line" /> */}

                <div className="user-info item">
                    <Dropdown menu={{ items: menuCfg }}>
                        <div className="default-content">
                            <UserOutlined className="icon-item" />
                            <span className="name">{state.user?.tenantName || state.user?.username}</span>
                        </div>
                    </Dropdown>
                </div>
            </div>
        </header>
    );
};
