/* eslint-disable camelcase */
// 系统根路由前缀
const BASE_NAME = '/console';

// 登录地址
const LOGIN_PATH = '/iam/login';
const MENU_PATH = {
    resourceCenter: '/console/iam/#/user/info',
    costCenter: '/console/iam/#/cost/center'
};

const LANG = {
    zhCN: { key: 'zh-CN', label: '中文' },
    enUS: { key: 'en-US', label: 'En' }
};

const QUOTA_SUPPLIER_DESP = {
    openAiChat: { title: 'AI工具', balance: 'AI工具余额', icon: 'chat' },
    openAiDALLE: { title: 'AI绘画', balance: 'AI绘画余额', icon: 'picture' },
    techPipeLine: { title: '拓客流水线', balance: '拓客流水线余额', icon: 'pipeline' },
    tytechSpiderService: { title: '拓客', balance: '拓客余额', icon: 'pipeline' }
};

const QUOTA_MARGIN_TITLE = {
    input: { name: '输入', unit: '字' },
    output: { name: '输出', unit: '字' },
    times: { name: '运行时长(分钟)', unit: '分钟' },
    '256x256': { name: '256x256', unit: '张' },
    '512x512': { name: '512x512', unit: '张' },
    '1024x1024': { name: '1024x1024', unit: '张' },
    numberOfTimes: '生成文章(篇)',
    usageCount_numberOfTimes: '生成贺卡/视频(次)',
    whaleUsageCount_totalSize: '流量(KB)',
    ioTWhaleUsageCount_totalSize: '流量(KB)',
    spiderUsageCount_numberOfTimes: 'TK店铺数(个)',
    spiderUsageCount_totalSize: 'TK店铺数(个)'
};

export { BASE_NAME, LANG, LOGIN_PATH, QUOTA_SUPPLIER_DESP, QUOTA_MARGIN_TITLE, MENU_PATH };
