export default {
    TITLE_HEADER_DOCUMENT_CENTER: '文档中心',
    TITLE_HEADER_INRELIIGENT_CUSTOMER_SERVICE: '智能客服',
    TITLE_HEADER_SHARE: '分享',
    TITLE_HEADER_PERSONAL_CONTER: '个人中心',
    TITLE_HEADER_COST_CONTER: '充值管理',
    TITLE_HEADER_RESOURCE_CONTER: '资源中心',
    TITLE_HEADER_OPERATION_CONTER: '运维中心',
    TITLE_HEADER_JOINRETAIL_CONTER: '加盟分销',
    TITLE_HEADER_IOT_CARD: '物联网卡',
    TITLE_HEADER_TRAFFIC_CARD: '流量卡',
    TITLE_HEADER_DEV_CUSTOMER_CENTER: '拓客管理',
    TITLE_HEADER_LOG_OUT: '退出登录',

    TIPS_HEADER_SHARE_COPY_SUCCESS: '复制分享链接成功。'
};
