/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { initGlobalState } from 'qiankun';
import { LANG } from '@utils/constants';
import { gotoLogin, setPageTitle, isWhaleCustomer, isHongCustomer } from '@utils/util';

const initialState = {
    lang: LANG.zhCN.key,
    user: {
        username: ''
    },
    menus: [],
    isMobile: false,
    isWhaleCustomer: isWhaleCustomer(), // 是否是鲸客
    isHongCustomer: isHongCustomer(), // 是否是拓云
    gotoLogin, // 给子应用统一提供跳转到登录界面的方法
    setPageTitle
};

const actions = initGlobalState(initialState);

actions.onGlobalStateChange((state, prev) => {
    for (const key in state) {
        initialState[key] = state[key];
    }

    console.log('主应用state 更新 initialState=', initialState);
});

actions.getGlobalState = (key) => {
    return key ? initialState[key] : initialState;
};

export default actions;
